<template>
  <div class="information">
  
        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <div style="display:none;margin-left:30px">
              <el-button type="primary" class="but" size="small" >
                <i class="el-icon-plus"></i>
                {{$i18n.t('navLeft.add')}}
              </el-button>
              <el-button type="primary" class="but" size="small" >
                <icon-svg icon-class="u1562" class="iii" />
                {{$t('tablename.scewm')}}
              </el-button>
            </div>
        </div>

    <Tables @handlerow="handlerow" :operation="operation" @handleSizeChange="handleSizeChange" @handlpages="handlpages" :tableData="tableData" :tableLabel="gettableLabel" :flags="flags" :configs="configs">
        <div slot="Dialogs">
          <el-dialog :title="$t('tablename.xgqyzlH')" width="30%" append-to-body :visible.sync="dialogFormVisible">
            <el-form :model="form">
              <el-form-item :label="$t('tablename.gsmcH')" :label-width="formLabelWidth">
                <el-input v-model="form.name" auto-complete="off"></el-input>
              </el-form-item>
              <el-form-item :label="$t('tablename.lxdh')" :label-width="formLabelWidth">
                <el-input v-model="form.mobile" auto-complete="off"></el-input>
              </el-form-item>
              <el-form-item :label="$t('tablename.yxdz')" :label-width="formLabelWidth">
                <el-input v-model="form.email" auto-complete="off"></el-input>
              </el-form-item>
              <!-- <div class="city"> -->
                  <el-form-item :label="$t('tablename.szsf')" :label-width="formLabelWidth" v-if="this.$i18n.locale == 'cn'">
                    <el-select v-model="form.province" placeholder="请选择活动区域" @change="provinceChange">
                      <el-option
                        v-for="item in provinceData"
                        :key="item"
                        :label="item"
                        :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item :label="$t('tablename.szcs')" v-if="this.$i18n.locale == 'cn'" :label-width="formLabelWidth">
                  <el-select v-model="form.city" placeholder="">
                      <el-option 
                        v-for="item in cityData"
                        :key="item"
                        :label="item"
                        :value="item">
                      </el-option>
                  </el-select>
                </el-form-item>
              <!-- </div> -->
              <el-form-item :label="$t('tablename.xxdz')" :label-width="formLabelWidth">
                <el-input v-model="form.address" auto-complete="off"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="saveEEEditData">{{$t('tablename.qd')}}</el-button>
            </div>
          </el-dialog>
        </div>
    </Tables>
  </div>
</template>
<script>
import Tables from '@/components/table'
import methodlist from '../../utils/methods'
import citylist from '../../utils/citylist'
import '@/icons/u1562.svg' 
import { mapState } from 'vuex'
export default {
  components: {
    Tables
  },
  data () {
    return {
      tableData: [],
      flags: {
        show: false, // 多选是否显示
        ishow: false // 序号是否显示
      },
      configs: {
        pagesize: 10,
        total: 200,
        loading: false
      },
     
      dialogFormVisible: false,
      form: {
        id: '',
        name: '',
        mobile: '',
        email: '',
        province: '',
        city: '',
        address: ''
        // delivery: false,
        // type: [],
        // resource: '',
        // desc: ''
      },
      provinceData:[],
      cityData:[],
      formLabelWidth: '120px',
      lang:''
    }
  },
  
  computed:{
     operation: function(){
       return{
        width: 180,
        data: [
          {
            name: this.$t('tablename.bj'),
            type: 'primary'
          }
        ]
     }
    },
    gettableLabel(){
      return [
        // {
        //   prop: 'id',
        //   label: 'ID编号',
        //   align: 'center'
        // },
        {
          prop: 'name',
          label: this.$t('tablename.qymc'),
          align: 'center'
        },
        {
          prop: 'mobile',
          label: this.$t('tablename.lxdh'),
          align: 'center'
        },
        {
          prop: 'email',
          label: this.$t('tablename.yxdz'),
          align: 'center'
        },
        {
          prop: 'province',
          label: this.$t('tablename.szsf'),
          align: 'center'
        },
        {
          prop: 'city',
          label: this.$t('tablename.szcs'),
          align: 'center'
        },
        {
          prop: 'address',
          label: this.$t('tablename.xxdz'),
          align: 'center'
        }
      ]
    }
  },
  mounted () {
    this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
    this.provinceData=citylist.provinceData;
    this.cityData = citylist.beijing;
    this.form.province = "北京";
    this.form.city = "东城区";
    this.getEEDataList(1);
  },
  methods: {
    // 编辑
    handlerow (v) {
      this.dialogFormVisible = true
      console.log(v)
      this.getEEEditData(v.id);
    },
    // 分页
    handlpages (v) {
      console.log(v);
      this.getEEDataList(v);
    },
    handleSizeChange(val){
      this.getEEDataList(1,val);
    },

    //获取企业资料列表
    getEEDataList:function(pageIndex,val){      
      const params = {
          method: methodlist.ee_enterprise_list,
          agent_id: this.UserInfo.agent_id,
          manager_id: this.UserInfo.manager_id, 
          page_index: pageIndex, 
          page_size:val?val: this.configs.pagesize
      }
      this.$serve(params).then(res => {
            console.log("获取企业资料",res); 
          this.tableData = res.data.data;
          this.configs.total = res.data.count;
          console.log(this.tableData);
      });
    },

    //企业资料获取
    getEEEditData:function(id){
      const params = {
          method: methodlist.ee_enterprise_get,
          id: id
      }
      this.$serve(params).then(res => {          
          console.log(res);
          this.form = res.data.data;
      });
    },
    //编辑保存企业资料
    saveEEEditData:function(){
      const params = {
          method: methodlist.ee_enterprise_edit,
          name: this.form.name,
          mobile: this.form.mobile,
          email:this.form.email,
          province:this.form.province,
          city:this.form.city,
          address:this.form.address,
          id:this.form.id
      }
      this.$serve(params).then(res => {          
          console.log(res);
          if(res.data.code == 0){
            this.$message({
              type: 'success',
              message: this.$t('devtable.xgcg'),
              center: true,
              duration:2000,
              offset:100
            });
            this.dialogFormVisible = false;
          }
          this.getEEDataList(1)
      });
    },
    
    //选择省份时刷新城市
    provinceChange:function(){
      console.log(this.form.province);
      switch (this.form.province) {
					case "北京":
						this.cityData = citylist.beijing;
						break;
					case "上海":
						this.cityData = citylist.shanghai;
						break;
					case "天津":
						this.cityData = citylist.tianjing;
						break;
					case "重庆":
						this.cityData = citylist.chongqing;
						break;
					case "浙江":
						this.cityData = citylist.zhejiang;
						break;
					case "江苏":
						this.cityData = citylist.jiangsu;
						break;
					case "广东":
						this.cityData = citylist.guangdong;
						break;
					case "福建":
						this.cityData = citylist.fujiang;
						break;
					case "湖南":
						this.cityData = citylist.hunan;
						break;
					case "湖北":
						this.cityData = citylist.hubei;
						break;
					case "辽宁":
						this.cityData = citylist.liaoning;
						break;
					case "吉林":
						this.cityData = citylist.jilin;
						break;
					case "黑龙江":
						this.cityData = citylist.heilongjiang;
						break;
					case "河北":
						this.cityData = citylist.hebei;
						break;
					case "河南":
						this.cityData = citylist.henan;
						break;
					case "山东":
						this.cityData = citylist.shandong;
						break;
					case "陕西":
						this.cityData = citylist.shangxi;
						break;
					case "甘肃":
						this.cityData = citylist.gansu;
						break;
					case "新疆":
						this.cityData = citylist.xinjiang;
						break;
					case "青海":
						this.cityData = citylist.qinghai;
						break;
					case "山西":
						this.cityData = citylist.shanxi;
						break;
					case "四川":
						this.cityData = citylist.sichuan;
						break;
					case "贵州":
						this.cityData = citylist.guizhou;
						break;
					case "安徽":
						this.cityData = citylist.anhui;
						break;
					case "江西":
						this.cityData = citylist.jiangxi;
						break;
					case "云南":
						this.cityData = citylist.yunnan;
						break;
					case "内蒙古":
						this.cityData = citylist.neimenggu;
						break;
					case "西藏":
						this.cityData = citylist.xizang;
						break;
					case "广西":
						this.cityData = citylist.guangxi;
						break;
					case "宁夏":
						this.cityData = citylist.ningxia;
						break;
					case "海南":
						this.cityData = citylist.hainan;
						break;
					case "香港":
						this.cityData = citylist.xianggang;
						break;
					case "澳门":
						this.cityData = citylist.aomeng;
						break;
					case "台湾":
						this.cityData = citylist.taiwan;
            break;
           
        }
        this.form.city=this.cityData[0];
    }
  }
}
</script>
<style lang="less" scoped>
.information{
  background-color: #fff;
  padding: 20px;
}
.el-form{
  width: 90%;
}
.city{
  display: flex;
  .el-form-item{
    flex: 2;
  }
}
.iii{
  width: 13px;
  height: 12px;
  margin-right: 3px;
}
</style>
